import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import {
  Section,
  Container,
  Image,
  FakeVideoPlayer,
  PopUp,
  YouTubePlayer,
} from 'components';
import './Latest.scss';
import { latestData } from 'mock/data.js';

const Latest = () => {
  const [videoPopupVisible, setVideoPopupVisible] = useState(false);

  return (
    <>
      <Section
        id={'tt-latest'}
        additionalClass={'section latest'}
        color={'dark'}
      >
        <div className={'wrapper'}>
          <Fade left duration={1000} delay={400} distance={'30px'}>
            <div className={'latest__player'}>
              <FakeVideoPlayer
                title={'test'}
                handleClick={() => setVideoPopupVisible(true)}
              >
                <Image
                  src={latestData.thumb}
                  alt={'TikTalk na YouTube'}
                  additionalClass={'hero__content__video-img'}
                  placeholder
                />
              </FakeVideoPlayer>
            </div>
          </Fade>
          <Fade bottom duration={1000} delay={600} distance={'30px'}>
            <div className={'latest__description'}>
              <Container
                side
                title={latestData.title}
                description={latestData.description}
                link={latestData.link}
                linkLabel={latestData.label}
                theme={'light'}
              />
            </div>
          </Fade>
        </div>
      </Section>
      <PopUp
        visible={videoPopupVisible}
        visibilityHandler={(showPoup) => setVideoPopupVisible(showPoup)}
      >
        <YouTubePlayer src={latestData.url} playing />
      </PopUp>
    </>
  );
};

export default Latest;
