import React from 'react';
import Layout from 'components/layout';
import { Navbar, Footer } from '../components';
import HomePage from 'views/home';
import { pages } from 'config/pages';
import config from '../../meta/config';

const page = pages.default;
const url = `${config.siteUrl}`;
const canonical = `${url}`;

const Home = () => {
  return (
    <Layout {...{ page, canonical, url }}>
      <Navbar />
      <HomePage />
      <Footer />
    </Layout>
  );
};

export default Home;
