import React from 'react';
import { Hero, Numbers, Latest, About, Contact } from './subcomponents';
import { pages } from 'config/pages';
import './home.scss';

const HomePage = ({ page }) => {
  const { label } = page || pages.default;

  return (
    <div id={label} className={`page-container page--${label}`}>
      <Hero />
      <Numbers />
      <Latest />
      <About />
      <Contact />
    </div>
  );
};

export default HomePage;
