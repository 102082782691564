import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import ReactHtmlParser from 'react-html-parser';
import { Section, Heading, Container } from 'components';
import './Numbers.scss';
import { numbersData } from 'mock/data.js';

class Numbers extends Component {
  renderItems = () =>
    numbersData.numbers.map((item, index) => (
      <div className={'numbers__column text--bold m-40'} key={index}>
        <Heading
          type={4}
          size={'extralarge'}
          additionalClass={'numbers__value m-16'}
        >
          {item.value}
        </Heading>
        <div className={'numbers__label size--big'}>
          {ReactHtmlParser(item.subtitle)}
        </div>
      </div>
    ));

  render() {
    return (
      <>
        <Section id={'tt-numbers'} additionalClass={'numbers'}>
          <Fade bottom duration={1000} delay={600} distance={'30px'}>
            <Container
              top
              title={numbersData.title}
              description={numbersData.description}
              backgroundShape
            />
          </Fade>
          <Fade bottom duration={1000} delay={600} distance={'30px'}>
            <div className={'numbers__wrapper'}>{this.renderItems()}</div>
          </Fade>
        </Section>
      </>
    );
  }
}

export default Numbers;
