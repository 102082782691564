import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import * as Scroll from 'react-scroll';
import ReactHtmlParser from 'react-html-parser';
import {
  Heading,
  Link,
  Image,
  FakeVideoPlayer,
  PopUp,
  YouTubePlayer,
} from 'components';
import './Hero.scss';
import { getYoutubeThumbnailUrl } from 'utils';
import { heroData } from 'mock/data.js';

const SpyLink = Scroll.Link;

const Hero = () => {
  const [videoPopupVisible, setVideoPopupVisible] = useState(false);
  return (
    <>
      <section id={'tt-hero'} className={'hero'}>
        <div className={'w-container'}>
          <div className={'hero__content'}>
            <div className={'hero__content__header'}>
              <Fade left duration={600} delay={800}>
                <Heading
                  type={4}
                  size={'default'}
                  additionalClass={'heading--sub m-32'}
                >
                  {ReactHtmlParser(heroData.description)}
                </Heading>
              </Fade>
              <Fade left duration={800} delay={1000}>
                <Heading
                  type={1}
                  size={'huge'}
                  additionalClass={'heading--hero text--bold m-40'}
                >
                  {ReactHtmlParser(heroData.title)}
                </Heading>
                <SpyLink
                  id={'tt-numbers'}
                  to={'numbers'}
                  spy={false}
                  smooth
                  duration={500}
                  className={'link btn btn--shadowed transition--fast link--borderless'}
                  rel={'nofollow'}
                >
                  Zaczynajmy
                </SpyLink>
              </Fade>
            </div>
            <div className={'hero__content__video'}>
              <Fade right duration={800} delay={800}>
                <FakeVideoPlayer
                  title={'test'}
                  handleClick={() => setVideoPopupVisible(true)}
                >
                  <Image
                    src={getYoutubeThumbnailUrl(heroData.url)}
                    alt={'test'}
                    additionalClass={'hero__content__video-img'}
                    placeholder
                  />
                </FakeVideoPlayer>
              </Fade>
            </div>
          </div>
          <Fade left duration={800} delay={200}>
            <div className={'hero__background'} />
          </Fade>
          <div className={'hero__circle'} />
        </div>
      </section>
      <PopUp
        visible={videoPopupVisible}
        visibilityHandler={(showPoup) => setVideoPopupVisible(showPoup)}
      >
        <YouTubePlayer src={heroData.url} playing />
      </PopUp>
    </>
  );
};

export default Hero;
