import React, { Component, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { IconSVG } from 'components';
import ReactHtmlParser from 'react-html-parser';
import Slider from 'react-slick';
import {
  Section,
  SliderArrow,
  Container,
  Heading,
  Paragraph,
} from 'components';
import { classNames } from 'utils';
import { aboutData } from 'mock/data.js';
import './About.scss';

const sliderSettings = {
  className: 'press__slider',
  dotsClass: 'slick-dots slick-pagination',
  arrows: true,
  dots: true,
  autoplay: false,
  adaptiveHeight: false,
  infinite: false,
  draggable: true,
  slidesToShow: 3,
  slidesToScroll: 2,
  nextArrow: <SliderArrow id={'press__slider-next'} />,
  prevArrow: <SliderArrow id={'press__slider-prev'} left />,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const About = () => {
  const [boxVisible, setBoxVisible] = useState(null);

  return (
    <Section id={'tt-about'} additionalClass={'section--smaller about'}>
      <div className={'wrapper'}>
        <Container
          top
          title={'Współpraca? Czemu nie?! :)'}
          description={
            'Jeśli zastanawiasz się nad promocją swojego produktu, usługi albo firmy, którą prowadzisz – napisz do mnie! Na pewno udan nam się coś wspólnie wymyślić.'
          }
        />
        <Fade top duration={1000} delay={300} distance={'30px'}>
          <div className={'about__wrapper'}>
            <Slider {...sliderSettings}>
              {aboutData.map((item, index) => (
                <div
                  className={classNames('about__column', {
                    'item--visible': boxVisible === index,
                    'about__box--cancelled': item.isCancelled,
                  })}
                  key={index}
                  >
                  <div
                    className={'about__box'}
                    style={{
                      background: `url(${item.image}) center center no-repeat`,
                    }}
                  />
                  <div className={'about__box--content'}>
                    <div className={'about__box--front-panel'}>
                      <Heading
                        type={4}
                        size={'large'}
                        additionalClass={'about__title text--bold m-16'}
                      >
                        {item.title}
                      </Heading>
                      <button
                        className={'about__link link link--anim-arrow'}
                        onClick={() => setBoxVisible(index)}
                      >
                        Pokaż więcej{' '}
                        <IconSVG name={'arrow-down'} color={'black'} />
                      </button>
                    </div>
                    <div className={'about__box--back-panel'}>
                      <button
                        className={'about__link link link--anim-arrow'}
                        onClick={() => setBoxVisible(false)}
                      >
                        Pokaż mniej{' '}
                        <IconSVG name={'arrow-up'} color={'black'} />
                      </button>
                      <Paragraph size={'default'} color={'black'}>
                        {ReactHtmlParser(item.description)}
                      </Paragraph>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Fade>
      </div>
    </Section>
  );
};

export default About;
