import React from 'react';
import Fade from 'react-reveal/Fade';
import { Section, Container, Link } from 'components';
import { contactData } from 'mock/data.js';
import './Contact.scss';

const Contact = () => (
  <Section id={'tt-contact'} additionalClass={'section contact'}>
    <div className={'contact__wrapper'}>
      <Fade left duration={1000} delay={200} distance={'30px'}>
        <Container
          side
          title={contactData.title}
          description={contactData.description}
          theme={'light'}
        />
      </Fade>
      <Fade top duration={1000} delay={200} distance={'30px'}>
        <Link
          to={contactData.button.link}
          button
          shadow
          additionalClass={'transition--fast link--borderless'}
        >
          {contactData.button.label}
        </Link>
      </Fade>
    </div>
  </Section>
);

export default Contact;
